import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    var this$1 = this;
    if (!onComplete && !onAbort && typeof Promise !== "undefined") {
        return new Promise(function (resolve, reject) {
            this$1.history.push(location, resolve, reject);
        });
    } else {
        this.history.push(location, onComplete, onAbort);
    }
};

const routes = [
    {
        path: "/",
        redirect: "/jingdian/index",
        component: Layout,
        children: [
            {
                path: "/jingdian/index",
                name: "首页",
                component: () => import("@/views/jingdian/index"),
                meta: {
                    showNav: true,
                },
            },
            {
                path: "/jingdian/search",
                name: "景点搜索",
                component: () => import("@/views/jingdian/search"),
            },
            {
                path: "/jingdian/share",
                name: "分享景点",
                component: () => import("@/views/jingdian/share"),
            },
            {
                path: "/jingdian/jingdianDetail",
                name: "景点详情",
                component: () => import("@/views/jingdian/jingdianDetail"),
            },
            {
                path: "/jingdian/personDetail",
                name: "人物详情",
                component: () => import("@/views/jingdian/personDetail"),
            },
            {
                path: "/jingdian/storyDetail",
                name: "故事详情",
                component: () => import("@/views/jingdian/storyDetail"),
            },
            {
                path: "/youxian/index",
                name: "游线",
                component: () => import("@/views/youxian/index"),
                meta: {
                    showNav: true,
                },
            },
            {
                path: "/yx/index",
                name: "游线",
                component: () => import("@/views/yx/index"),
                meta: {
                    showNav: false,
                },
            },
            {
                path: "/youxian/search",
                name: "游线搜索",
                component: () => import("@/views/youxian/search"),
            },
            {
                path: "/youxian/youxianDetail1",
                name: "游线详情",
                component: () => import("@/views/youxian/youxianDetail1"),
            },
            {
                path: "/youxian/youxianDetail2",
                name: "游线详情",
                component: () => import("@/views/youxian/youxianDetail2"),
            },
            {
                path: "/youxian/youxianMap",
                name: "游线线路",
                component: () => import("@/views/youxian/youxianMap"),
            },
            {
                path: "/daka",
                name: "古风打卡",
                component: () => import("@/views/daka/index"),
                meta: {
                    showNav: false,
                },
            },
            {
                path: "/daka/list",
                name: "古风打卡",
                component: () => import("@/views/daka/list"),
                meta: {
                    showNav: false,
                },
            },
            {
                path: "/daka/upload",
                name: "古风打卡",
                component: () => import("@/views/daka/upload"),
                meta: {
                    showNav: false,
                },
            },
            {
                path: "/daka/share",
                name: "古风打卡分享",
                component: () => import("@/views/daka/share"),
                meta: {
                    showNav: false,
                },
            },
            {
                path: "/keting/index",
                name: "文学客厅",
                component: () => import("@/views/literaryLivingRoom"),
                meta: {
                    showNav: true,
                },
            },
            {
                path: "/keting/city",
                name: "城市文学活动",
                component: () => import("@/views/keting/city"),
                meta: {
                    showNav: true,
                },
            },
            {
                path: "/keting/cityDetail",
                name: "城市文学活动",
                component: () => import("@/views/keting/cityDetail"),
            },
            {
                path: "/keting/roomDetail",
                name: "活动详情",
                component: () => import("@/views/literaryLivingRoom/roomDetail"),
            },
            {
                path: "/keting/share",
                name: "活动分享",
                component: () => import("@/views/keting/share"),
            },
            {
                path: "/keting/roomServe",
                name: "客厅服务",
                component: () =>
                    import("@/views/literaryLivingRoom/roomDetail/roomServe.vue"),
            },
            {
                path: "/keting/roomServeDetail",
                name: "客厅服务",
                component: () =>
                    import("@/views/literaryLivingRoom/roomDetail/roomServeDetail.vue"),
            },
            {
                path: "/keting/yuyue",
                name: "预约查询",
                component: () =>
                    import("@/views/literaryLivingRoom/roomDetail/yuyue.vue"),
            },
            {
                path: "/wendu",
                name: "关于文都",
                component: () => import("@/views/wendu/index"),
                meta: {
                    showNav: true,
                },
            },

            {
                path: "/tansuo",
                name: "探索",
                component: () => import("@/views/tansuo/index"),
                meta: {
                    showNav: true,
                },
            },
            {
                path: "/wendu/android",
                name: "关于文都",
                component: () => import("@/views/wendu/android"),
                meta: {
                    showNav: false,
                },
            },
            {
                path: "/wendu/index",
                name: "关于文都",
                component: () => import("@/views/wendu/wenduindex2"),
            },
            {
                path: "/keting/wendu",
                name: "关于文都",
                component: () => import("@/views/wendu/index2"),
            },
            {
                path: "/keting/sjwenxue",
                name: "世界文学",
                component: () => import("@/views/wendu/shijiewenxue/index"),
            },
            {
                path: "/keting/sjwenxueDetail",
                name: "世界文学",
                component: () => import("@/views/wendu/shijiewenxue/imageDetail"),
            },
            {
                path: "/keting/nanjing",
                name: "文都南京",
                component: () => import("@/views/wendu/nanjing"),
            },
            {
                path: "/keting/dajiating",
                name: "文都大家庭",
                component: () => import("@/views/wendu/dajiating"),
            },
            {
                path: "/keting/nanjingDetail",
                name: "文都南京",
                component: () => import("@/views/wendu/nanjing/detail"),
            },
            {
                path: "/keting/liuchao",
                name: "文都代言人",
                component: () => import("@/views/wendu/daiyanreng/liuchao"),
            },
            {
                path: "/keting/daiyanreng",
                name: "文都代言人",
                component: () => import("@/views/wendu/daiyanreng/index"),
            },
            {
                path: "/keting/daiyanrenDetail",
                name: "文都代言人",
                component: () => import("@/views/wendu/daiyanreng/detail"),
            },
            {
                path: "/keting/miankong",
                name: "世界面孔",
                component: () => import("@/views/wendu/miankong"),
            },
            {
                path: "/keting/xingshijie",
                name: "文都行世界",
                component: () => import("@/views/wendu/xingshijie"),
            },
            {
                path: "/keting/dashiji",
                name: "世界大事记",
                component: () => import("@/views/wendu/dashiji"),
            },
            {
                path: "/wenxueta/index",
                name: "文学塔",
                component: () => import("@/views/wenxueta/index"),
            },
            {
                path: "/wenxueta/detail",
                name: "问题",
                component: () => import("@/views/wenxueta/chuansuo/index"),
            },
            {
                path: "/wenxueta/map",
                name: "图层",
                component: () => import("@/views/wenxueta/ditu/index.vue"),
            },
            {
                path: "/wenxueta/wechatt",
                name: "wx",
                component: () => import("@/views/wenxueta/wechat/index.vue"),
            },
            {
                path: "/wenxueta/gif",
                name: "gif",
                component: () => import("@/views/wenxueta/wenxueGif/index.vue"),
            },
            {
                path: "/wenxueta/dangdai",
                name: "当代",
                component: () => import("@/views/wenxueta/dangdai/index.vue"),
            },

            {
                path: "/qzwd/index",
                name: "文都气质",
                component: () => import("@/views/qzwd/index.vue"),
            },
            {
                path: "/qzwd/dati",
                name: "文都气质答题",
                component: () => import("@/views/qzwd/dati.vue"),
            },
            {
                path: "/qzwd/share",
                name: "文都气质分享",
                component: () => import("@/views/qzwd/share.vue"),
            },
            {
                path: "/message/index",
                name: "诗意南京",
                component: () => import("@/views/message/index.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
});

function loadJS1(url) {
    return new Promise((resolve) => {
        let script = document.createElement("script");
        script.src = url;
        // IE
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState == "loaded" || script.readyState == "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // 其他浏览器
            script.onload = function () {
                resolve();
            };
        }
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}
function loadJS(url) {
    return new Promise((resolve) => {
        let script = document.createElement("script");
        script.src = url;
        script.type = "text/javascript";
        // IE
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState == "loaded" || script.readyState == "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // 其他浏览器
            script.onload = function () {
                resolve();
            };
        }
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}

function loadJsAll() {
    return Promise.all([
        loadJS(
            "https://map.qq.com/api/gljs?v=2.exp&key=2VSBZ-ZI43W-FJBRU-3P5EA-QUSOQ-32BIA&rand=1"
        ),
        loadJS1(
            "https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js?rand=1"
        ),
    ]);
}

router.beforeEach((to, from, next) => {
    if (to.path === "/jingdian/index" || to.path === "/wenxueta/dangdai") {
        loadJsAll().then((res) => {
            next();
        });
    } else {
        next();
    }
});


export default router;
