<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            userInfo: {},
            phone: "",
            openId: "",
        };
    },
    mounted() {
        let that = this;
        this.$router.onReady(() => {
            if (that.$route.query.phone && that.$route.query.phone != "null") {
                that.getOpenId(that.$route.query.phone);
            }
        });
        // https://tmwd-test.nuoqu.net/tools/app.ashx?action=getusermobile&mobile=15005194202
    },
    methods: {
        getOpenId(phone) {
            if (!phone) return;
            let that = this;
            this.$getData("getusermobile", {
                mobile: phone,
            }).then((res) => {
                that.userInfo = res.data;
                localStorage.setItem("userInfo", JSON.stringify(that.userInfo));
                localStorage.setItem("opendID", res.data.openid);
                that.phone = phone;
                that.openId = res.data.openid;
            });
            // localStorage.setItem("source",this.$route.query.source)
            // this.cats = catData.data;
            // /tools/app.ashx?action=Gettikufenlei&openid=oOWPl5KvySnvaXX8DzRtuO7ps4Iw
            // const xlbiaoqianData = await this.$getData("Getxlbiaoqian");
            // this.xlbiaoqianData = xlbiaoqianData.data;
            // this.params.id = this.xlbiaoqianData[0].id;
            // this.getbiaoqiantoxl();
        },
    },
};
</script>
<style lang="scss">
* {
    box-sizing: border-box;
}
// https://wxdtoss.oss-cn-beijing.aliyuncs.com/fonts/SourceHanSerifSC-Bold.otf
// https://wxdtoss.oss-cn-beijing.aliyuncs.com/fonts/SourceHanSansSC-Regular.otf
@font-face {
    font-family: "Source Han Serif SC-Bold";
    src: url("https://wxdtoss.oss-cn-beijing.aliyuncs.com/fonts/SourceHanSerifSC-Bold.otf");
}

@font-face {
    font-family: "Source Han Serif SC";
    src: url("https://wxdtoss.oss-cn-beijing.aliyuncs.com/fonts/SourceHanSansSC-Regular.otf");
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
