/*
 * @Date: 2023-09-04 13:40:14
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-09-20 09:08:07
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \nuoqu\src\store\modules\gailan\index.js
 */

import { resPageDataCheck } from "@/utils/resMessage"

const state = {
  gailanData:null
}

const mutations = {
  "SETDATA":(state,wenduData)=>{
    state.gailanData = wenduData
  }
}
const actions = {
  async getWxzdglh5({ commit },that){
    let wendu = await that.$getData("Getwxzdglh5")
    let wenduData = resPageDataCheck(wendu,'Getwxzdglh5').data[0]
    commit("SETDATA",wenduData)

  }
}

const getters = {
  gailanData:(state)=>state.gailanData
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}