<template>
    <div class="nav">
        <ul class="nav">
            <li
                :class="{ active: $route.path.indexOf('/jingdian/') > -1 }"
                @click="routerPush('/jingdian/index')"
            >
                <img src="@/assets/index/nav1.png" class="img1" />
                <img src="@/assets/index/nav1_active.png" class="img2" />
            </li>
            <li
                :class="{ active: $route.path.indexOf('/youxian/') > -1 }"
                @click="routerPush('/youxian/index')"
            >
                <img src="@/assets/index/nav2.png" class="img1" />
                <img src="@/assets/index/nav2_active.png" class="img2" />
            </li>

            <!-- <li
                :class="{ active: $route.path.indexOf('/wendu') > -1 }"
                @click="routerPush('/wendu')"
            >
                <img src="@/assets/index/nav4.png" class="img1" />
                <img src="@/assets/index/nav4_active.png" class="img2" />
            </li> -->

            <li
                :class="{ active: $route.path.indexOf('/tansuo') > -1 }"
                @click="routerPush('/tansuo')"
            >
                <img src="@/assets/index/c2.png" class="img1" />
                <img src="@/assets/index/c1.png" class="img2" />
            </li>

            <li
                v-if="!isAndroid"
                :class="{ active: $route.path.indexOf('/keting/') > -1 }"
                @click="routerPush('/keting/index')"
            >
                <img src="@/assets/index/nav3.png" class="img1" />
                <img src="@/assets/index/nav3_active.png" class="img2" />
            </li>

            <!-- <li
                :class="{ active: $route.path.indexOf('/wendu') > -1 }"
                @click="routerPush('/wendu')"
            >
                <img
                    src="https://wxdtoss.oss-cn-beijing.aliyuncs.com/upload/202307/07/202307071245148129.png"
                    class="img1"
                />
                <img src="@/assets/index/nav4_active.png" class="img2" />
            </li> -->

            <!-- <li
                v-if="isAndroid"
                :class="{ active: $route.path.indexOf('/keting/') > -1 }"
                @click="routerPush('/keting/index')"
            >
                <img src="@/assets/index/nav3.png" class="img1" />
                <img src="@/assets/index/nav3_active.png" class="img2" />
            </li> -->
            <!--      <li-->
            <!--          :class="{ active: $route.path.indexOf('/wenxueta/') > -1 }"-->
            <!--          @click="routerPush('/wenxueta/index')"-->
            <!--      >-->
            <!--        <img src="@/assets/index/nav3.png" class="img1" />-->
            <!--        <img src="@/assets/index/nav3_active.png" class="img2" />-->
            <!--      </li>-->
        </ul>
        <!-- <div style="z-index:99;width: 100%;">
      {{ getUrl }}

    </div> -->
    </div>
</template>
<script>
import { tabbarRouter } from "@/router/index";

export default {
    data() {
        return {
            active: 0,
            isAndroid: false,
            tabbarImageList: [
                {
                    image: require("@/assets/index/nav1.png"),
                    activeImage: require("@/assets/index/nav1_active.png"),
                },
                {
                    image: require("@/assets/index/nav2.png"),
                    activeImage: require("@/assets/index/nav2_active.png"),
                },
                {
                    image: require("@/assets/index/nav4.png"),
                    activeImage: require("@/assets/index/nav4_active.png"),
                },
                {
                    image: require("@/assets/index/nav3.png"),
                    activeImage: require("@/assets/index/nav3_active.png"),
                },
            ],
        };
    },
    mounted() {
        let that = this;
        this.$router.onReady(() => {
            if (that.$route.path == "/wendu/android") {
                that.isAndroid = true;
            }
        });
    },
    computed: {
        getTabbarRouterList() {
            let _this = this;
            console.info("tabbarRouter", tabbarRouter);
            let routerList = tabbarRouter.map((ele, index) => {
                return {
                    topath: ele.path,
                    ..._this.tabbarImageList[index],
                    // ...ele.children[0],
                };
            });
            console.info("routerList", routerList);
            return routerList;
        },
    },
    methods: {
        routerPush(path) {
            if (path != this.$route.path) {
                if (path == "/jingdian/index") {
                    // let phone = localStorage.getItem("phone");
                    // if (phone && phone != null) {
                    //     path = "/jingdian/index?phone=" + phone;
                    // }
                }
                this.$router.push({
                    path,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.nav {
    width: 100%;
    height: 130px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        width: 80px;
        margin: 0 30px;
        .img1 {
            width: 100%;
            display: block;
        }
        .img2 {
            width: 100%;
            display: none;
        }
        &.active {
            .img1 {
                width: 100%;
                display: none;
            }
            .img2 {
                width: 100%;
                display: block;
            }
        }
    }
}
</style>
