/*
 * @Description:
 * @Autor: 张健
 * @Date: 2021-06-08 13:19:25
 * @LastEditors: 张健
 * @LastEditTime: 2022-09-02 12:46:10
 */

import axios from 'axios';
import { Toast } from 'vant'


const service = axios.create({
  baseURL: process.env.VUE_API_URL,
  timeout: 3000000,
});
service.interceptors.request.use(
  config => {
    return config;

  },
  error => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  res => {
    if (res.config.responseType === 'blob') {
      return res;
    }
    if (res.data.code == 0 || res.data.code == undefined) {
      return res.data;
    } else {
      Toast.fail(res.data.msg);
      return Promise.reject(res.data.msg);
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;

