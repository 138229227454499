/*
 * Copyright 2019-2020 Zheng Jie
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Vue from 'vue';
import { getDicts } from '@/api/index';

export default class Dict {
  constructor(dict) {
    this.dict = dict;
  }

  async init(names, completeCallback) {
    if (names === undefined || names === null) {
      throw new Error('need Dict names');
    }
    const ps = [];
    names.forEach((n) => {
      Vue.set(this.dict.dict, n, {});
      Vue.set(this.dict.label, n, {});
      Vue.set(this.dict, n, []);
      ps.push(
        getDicts(n).then((data) => {
          const details = (data && data.data) || [];
          this.dict[n].splice(0, 0, ...details);
          details.forEach((d) => {
            Vue.set(this.dict.dict[n], d.value, d);
            Vue.set(this.dict.label[n], d.value, d.label);
          });
        })
      );
    });
    await Promise.all(ps);
    completeCallback();
  }
}
